 body {
    margin: 0;
    font-family: "Roboto",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    width: 100%;
    height: fit-content;
    background:#E9ECEF;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
    /* display: flex;
    flex-direction: row; */
    height: 100%;


}

html {
    height: 100%;
}

#root {
    height: 100%;
}
*, *::before, *::after {
    box-sizing: border-box;
}